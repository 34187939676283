<template>
  <div class="custom-footer">
    <footer class="footer" style="color: white">
      <div class="container">
        <div class="row">
          <div class="col-lg-4 col-12 mb-0 mb-md-4 pb-0 pb-md-2">
            <a href="#" class="logo-footer">
              <img v-bind:src="logo.light" height="100" alt="Company's logo" />
            </a>
            <p class="mt-4 text-justify">
              {{ props.FooterDescription }}
            </p>
            <ul class="list-unstyled mb-0 mt-3">
              <li class="list-inline-item ml-1">
                <a href="#" class="rounded">
                  <img class="redes" v-bind:src="props.Social1" />
                </a>
              </li>
              <li class="list-inline-item ml-2">
                <a href="#" class="rounded">
                  <img class="redes" v-bind:src="props.social2" />
                </a>
              </li>
              <li class="list-inline-item ml-2">
                <a href="#" class="rounded">
                  <img class="redes" v-bind:src="props.social3" />
                </a>
              </li>
            </ul>
          </div>
          <div class="col-lg-3 col-md-4 ml-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
            <h5 class="text-light footer-head">Compañia</h5>
            <ul class="list-unstyled footer-list mt-4 text-justify">
              <li class="house">
                <img
                  class="Flogos"
                  src="../../assets/footer/home_white_24dp.svg"
                />
                <i class="mdi mdi-chevron-right mr-1"></i> Av. Winston Churchill
                esq. Max Henríquez Ureña, Ens. Evaristo Morales
              </li>
              <li>
                <router-link to="/" class="text-foot">
                  <img
                    class="Flogos"
                    src="../../assets/footer/call_white_24dp.svg"
                  />
                  <i class="mdi mdi-chevron-right mr-1"></i>
                  +1 809-985-4527</router-link
                >
              </li>
              <li>
                <router-link to="/" class="text-foot">
                  <img
                    class="Flogos"
                    src="../../assets/footer/email_white_24dp.svg"
                  />
                  <i class="mdi mdi-chevron-right mr-1"></i>
                  servicioalcliente@petdr.do
                </router-link>
              </li>
              <li>
                <router-link to="/" class="text-foot">
                  <img
                    class="Flogos"
                    src="../../assets/footer/link_white_24dp.svg"
                  />
                  <i class="mdi mdi-chevron-right mr-1"></i> www.petdr.do
                </router-link>
              </li>
              <li>
                <img class="bgs" v-bind:src="props.suraIMG" alt="" />
              </li>
              <li></li>
            </ul>
          </div>
          <div class="col-lg-3 col-md-4 ml-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
            <h5 class="text-light footer-head">Links De Utilidad</h5>
            <ul class="list-unstyled footer-list mt-4 house">
              <li>
                <router-link to="/" class="text-foot"
                  ><i class="mdi mdi-chevron-right mr-1"></i> Terms &
                  servicios</router-link
                >
              </li>
              <li>
                <router-link to="/" class="text-foot"
                  ><i class="mdi mdi-chevron-right mr-1"></i>inicio</router-link
                >
              </li>
              <li>
                <router-link to="/" class="text-foot"
                  ><i class="mdi mdi-chevron-right mr-1"></i>somos</router-link
                >
              </li>
              <li>
                <router-link to="/" class="text-foot"
                  ><i class="mdi mdi-chevron-right mr-1"></i>
                  planes</router-link
                >
              </li>
              <li>
                <router-link to="/" class="text-foot"
                  ><i class="mdi mdi-chevron-right mr-1"></i>
                  proveedores</router-link
                >
              </li>
              <li>
                <router-link to="/" class="text-foot"
                  ><i class="mdi mdi-chevron-right mr-1"></i
                  >solicitud</router-link
                >
              </li>
              <li>
                <router-link to="/" class="text-foot"
                  ><i class="mdi mdi-chevron-right mr-1"></i> blog</router-link
                >
              </li>
              <li>
                <router-link to="/" class="text-foot"
                  ><i class="mdi mdi-chevron-right mr-1"></i>
                  contacto</router-link
                >
              </li>
            </ul>
          </div>
          <!-- <div class="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
            <h5 class="text-light footer-head">Boletin informativo</h5>
            <p class="mt-4" style="color: white">
              Resibe las ultimas noticias sobre nuestros planes
            </p>
            <form>
              <div class="row">
                <div class="col-lg-12">
                  <div class="foot-subscribe form-group">
                    <label
                      >Escribe tu correo
                      <span class="text-danger">*</span></label
                    >
                    <div class="position-relative">
                      <mail-icon class="fea icon-sm icons"></mail-icon>
                      <input
                        type="email"
                        name="email"
                        id="emailsubscribe"
                        class="form-control pl-5 rounded"
                        placeholder="Your email : "
                        required
                      />
                    </div>
                  </div>
                </div>
                <div class="col-lg-12">
                  <input
                    type="submit"
                    id="submitsubscribe"
                    name="send"
                    style="color: #ffffff !important"
                    class="btn btn-soft-primary btn-block"
                    value="Suscribirse"
                  />
                </div>
              </div>
            </form>
          </div> -->
        </div>
      </div>
    </footer>
    <footer class="footer">
      <div class="container text-center">
        <div class="row align-items-center">
          <div class="col-sm-6">
            <div class="text-sm-left">
              <p class="mb-0 text-foot">
                © 2021 PetDr. Plan
              </p>
            </div>
          </div>
          <!-- <div class="col-sm-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
            <ul class="list-unstyled text-sm-right mb-0">
              <li class="list-inline-item">
                <a href="javascript:void(0)"
                  ><img
                    src="/images/payments/american-ex.png"
                    class="avatar avatar-ex-sm"
                    title="American Express"
                    alt=""
                /></a>
              </li>
              <li class="list-inline-item ml-1">
                <a href="javascript:void(0)"
                  ><img
                    src="/images/payments/discover.png"
                    class="avatar avatar-ex-sm"
                    title="Discover"
                    alt=""
                /></a>
              </li>
              <li class="list-inline-item ml-1">
                <a href="javascript:void(0)"
                  ><img
                    src="/images/payments/master-card.png"
                    class="avatar avatar-ex-sm"
                    title="Master Card"
                    alt=""
                /></a>
              </li>
              <li class="list-inline-item ml-1">
                <a href="javascript:void(0)"
                  ><img
                    src="/images/payments/paypal.png"
                    class="avatar avatar-ex-sm"
                    title="Paypal"
                    alt=""
                /></a>
              </li>
              <li class="list-inline-item ml-1">
                <a href="javascript:void(0)"
                  ><img
                    src="/images/payments/visa.png"
                    class="avatar avatar-ex-sm"
                    title="Visa"
                    alt=""
                /></a>
              </li>
            </ul>
          </div> -->
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import {
  FacebookIcon,
  InstagramIcon,
  TwitterIcon,
  LinkedinIcon,
  MailIcon,
} from "vue-feather-icons";
export default {
  components: {
    FacebookIcon,
    InstagramIcon,
    TwitterIcon,
    LinkedinIcon,
    MailIcon,
  },
  props: {
    props: Object,
  },
  computed: {
    logo() {
      return this.$store.getters["system/logo"];
    },
  },
};
</script>

<style scoped>
.custom-footer {
  left: 50% !important;
  position: relative;
  transform: translateX(-50%);
  width: 100vw !important;
}
.redes {
  width: 15px;
  height: 15px;
}

.text-foot {
  font-size: 14px;
  color: white;
}

.bgs {
  width: 260px;
  height: 35px;
}

.house {
  font-size: 12px;
  color: white;
}

.Flogos {
  width: 17px;
  height: 17px;
}
</style>